.used-mobiles-parent-class {
  // 7B2CBF


  .active-brand {
    font-weight: bold;
    /* or any other style to indicate it's selected */
  }

  .filters_section_body_heading {
    display: none;
  }

  .filters_btn {
    display: none;
  }

  /* Example media query for hiding the sidebar on smaller screens */
  @media (max-width: 768px) {

    .filters_section {
      background-color: #ffffff;
      width: 0 !important;
      overflow-x: hidden !important;
      transition: all 0.3s;
      position: fixed;
      top: 0;
      right: 0;
      padding: 0;
    }

    .filters_section_heading {
      display: none;
    }

    .filters_btn {
      padding: 2.5px 3px 2.5px 2px;
      border-radius: 3.5px !important;
      background-color: #f8f9fa !important;
    }

    .show_filters_sidebar {

      width: 450px !important;
      height: 100vh;
      z-index: 1024 !important;
      margin: 0 0 0 46.5px;
      padding: 0 10px 0 10px;
      overflow-y: scroll;

    }


    .filters_section_body_heading {
      display: flex;
      align-items: center !important;
      justify-content: space-between !important;
      padding: 15px 0 7px;
    }

    .filters_section_body_heading h5 {
      display: flex;
      align-items: center;
    }

    .filters_btn {
      display: flex;
    }

  }


  /* Example media query for showing the button on smaller screens */
  @media (max-width: 768px) {
    .toggle-sidebar-button {
      display: block;
    }
  }

  @media (max-width: 808px) {
    .search__keywords {
      display: none;
    }
  }

  /* Example media query for hiding the button on larger screens */
  @media (min-width: 769px) {
    .toggle-sidebar-button {
      display: none;
    }
  }

  @media (max-width: 769px) {
    #paginatedMobiles {
      padding-left: 30px;
    }
  }


  // Add any additional styles you need

}