.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .overlay-content {
        background-color: #fff;
        padding: 2rem;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        max-width: 400px;
        width: 100%;
        max-height: 90vh;
        position: relative;
        overflow-y: scroll;
        scrollbar-width: none;

        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            font-size: 1.5rem;
        }
    }

    .signin-form,
    .signup-form {
        .form-group {
            margin-bottom: 1rem;
        }

        .btn-primary {
            background-color: #6a1b9a;
            border: none;
        }

        .btn-secondary {
            background-color: #000;
            border: none;
            color: #fff;
        }

        .forgot-password {
            color: #6a1b9a;
        }

        .input-group-text {
            background-color: transparent;
            border-left: none;
            cursor: pointer;
        }

        h2 {
            margin-bottom: 0.5rem;
            font-size: 20px;
            font-weight: bolder;
        }

        p {
            margin-bottom: 1.5rem;
        }

        a {
            color: #6a1b9a;
        }

        #remember-me,
        #agree-terms {
            width: 26px;
        }

        .text-center {
            text-align: center;
        }

        .btn-block {
            width: 100%;
            margin-top: 1rem;
        }

        .form-control {
            border-radius: 4px;
        }

        .text-cotinue {
            font-size: small;
            padding: 8px;
        }
    }
}

// .overlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(0, 0, 0, 0.7);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     z-index: 1000;

//     .overlay-content {
//         background-color: #fff;
//         padding: 2rem;
//         border-radius: 8px;
//         box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//         max-width: 400px;
//         width: 100%;
//         max-height: 90vh;
//         position: relative;

//         .close-icon {
//             position: absolute;
//             top: 10px;
//             right: 10px;
//             cursor: pointer;
//             font-size: 1.5rem;
//         }
//     }

//     .signin-form {
//         .form-group {
//             margin-bottom: 1rem;
//         }

//         .btn-primary {
//             background-color: #6a1b9a;
//             border: none;
//         }

//         .btn-secondary {
//             background-color: #000;
//             border: none;
//             color: #fff;
//         }

//         .forgot-password {
//             color: #6a1b9a;
//         }

//         .input-group-text {
//             background-color: transparent;
//             border-left: none;
//             cursor: pointer;
//         }

//         h2 {
//             margin-bottom: 0.5rem;
//             font-size: 20px;
//             font-weight: bolder;
//         }

//         p {
//             margin-bottom: 1.5rem;
//         }

//         a {
//             color: #6a1b9a;
//         }

//         #remember-me {
//             width: 26px;
//         }

//         .text-center {
//             text-align: center;
//         }

//         .btn-block {
//             width: 100%;
//             margin-top: 1rem;
//         }

//         .form-control {
//             border-radius: 4px;
//         }

//         .text-cotinue {
//             font-size: small;
//             padding: 8px;
//         }
//     }
// }