.testimonial {
  background-image: url('../../assets/Background.jpg');
  width: 100%;
}

.custom-slider .slick-dots {
  position: relative;
  bottom: 0;
  left: 48%;
  transform: translateX(-50%);
  // padding: 0 480px;
  display: flex !important;
  justify-content: center;

}

.custom-slider .slick-dots li {
  display: block;
  margin: 0 -3px;
}

.header__Top {
  font-size: 25px;
}

.header_Bottom {
  font-size: 16px;
  font-weight: 200;
}

@media (max-width: 467px) {
  .testimonial {
    height: 65vh;
  }

  .header__Top {
    font-size: 26px;
    margin-left: 20px;
  }


  .header__Bottom {
    margin-left: 20px;
    font-size: 19px;
    font-weight: 300;
  }
}