.screen1 {
    .parent-top-banner {
        display: flex;
        background-color: #F8F9FA;
        border-radius: 20px;
        overflow: hidden;
    }

    .left-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .right-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .custom-list-item {
        display: flex;
        align-items: flex-start;
        gap: 10px;
    }

    .btn-row {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 20px;
    }

    .custom-list-item {
        padding-bottom: 10px;
    }

    .custom-card-ad {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: calc(33.333% - 32px);
        /* Adjust for gutter width */
        margin-left: 16px;
        /* Adjust to create horizontal spacing */
        margin-right: 16px;
        /* Adjust to create horizontal spacing */
        padding: 20px;
        /* Adjust padding as needed */
        border-radius: 15px;
        /* Adjust border-radius as needed */
        background: linear-gradient(white, white) padding-box,
            linear-gradient(180deg, rgba(248, 248, 250, 0.63) 0%, #F1E4FB 100%) border-box;
        border: 2px solid transparent;
    }

    .parent-bottom-banner {
        display: flex;
        justify-content: center;
    }

    .parent-bottom-banner p {
        text-align: center;
        font-size: 16px;
        width: 80%;
        font-weight: 300;
    }

    .parent-bottom-banner h6 {
        padding-top: 20px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
    }

    @media (max-width: 768px) {
        .ad_img {
            width: 100%;
        }

        .custom-card-ad {
            margin-bottom: 12px;

            max-width: calc(100% - 32px);
        }
    }
}

// .screen1 {
//     .parent-top-banner {
//         display: flex;
//         background-color: #F8F9FA;
//         border-radius: 20px;
//         overflow: hidden;
//     }

//     .left-col {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;
//     }

//     .right-col {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//     }

//     .custom-list-item {
//         display: flex;
//         align-items: flex-start;
//         gap: 10px;
//     }

//     .btn-row {
//         display: flex;
//         align-items: center;
//         align-content: center;
//         gap: 20px;
//     }

//     .custom-list-item {
//         padding-bottom: 10px;
//     }

//     .custom-card-ad {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//     }

//     .parent-bottom-banner {
//         display: flex;
//         justify-content: center;
//     }

//     .parent-bottom-banner p {
//         text-align: center;
//         font-size: 16px;
//         width: 70%;
//         font-weight: 300;
//     }

//     .parent-bottom-banner h6 {
//         padding-top: 20px;
//         text-align: center;
//         font-size: 18px;
//         font-weight: bold;
//     }

//     .custom-card-ad {
//         // border-radius: 120px;
//         // border: 2px solid;
//         // border-image: linear-gradient(180deg, rgba(248, 248, 250, 0.63) 0%, #F1E4FB 100%);
//         // border-image-slice: 1;

//         position: relative;
//         padding: 20px; /* Adjust padding as needed */
//         border-radius: 15px; /* Adjust border-radius as needed */
//         background: linear-gradient(white, white) padding-box, 
//                     linear-gradient(180deg, rgba(248, 248, 250, 0.63) 0%, #F1E4FB 100%) border-box;
//         border: 2px solid transparent;
//     }

//     @media (max-width: 768px) {
//         .ad_img {
//             width: 100%;
//         }

//         .custom-card-ad {
//             margin-bottom: 12px;
//         }
//     }
// }