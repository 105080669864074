.user-profile-parent {

    .background-grey-a {
        background-color: #F8F9FA;
        border-radius: 20px;
        padding: 36px 40px;
    }

    .background-grey-b {
        background-color: #F8F9FA;
        border-radius: 20px;
        padding: 50px 40px;
    }

    .profile-photo img {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        object-fit: cover;
    }

    .profile-photo .info-icon {
        width: 14px;
        height: 14px;
    }

    .profile-photo p {
        color: gray;
        font-size: 12px;
    }

    p {

        margin-bottom: 0;
    }

    .profile-photo button {
        max-width: 120px;
        font-size: 14px;
    }

    .contact-info input {
        width: 100%;
    }

    .optional-info button {
        font-size: 14px;
        padding: 6px 14px;
        background-color: white;
    }

    input::placeholder {
        color: #888888 !important;
        opacity: 1;
    }

    textarea::placeholder {
        color: #888888 !important;
        opacity: 1;
    }

    @media (max-width: 768px) {

        .profile-photo,
        .contact-info,
        .optional-info {
            text-align: center;
        }
    }

}