.used-mobile-detail-parent-class {
    .image-gallery-thumbnails-wrapper .image-gallery-thumbnail {
        max-height: 150px;
        overflow: hidden;
        object-fit: cover;
    }

    .image-gallery-thumbnails-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .text-prop-key {
        overflow-wrap: break-word;
        max-width: 30%;
    }

    .text-prop-value {
        overflow-wrap: break-word;
        max-width: 60%;
    }

    .image-gallery-image {
        min-height: 80vh !important;
        max-height: 80vh !important;
    }

    .image-gallery-thumbnail-image {
        max-height: 120px;
        min-height: 120px;
        object-fit: contain;
    }

    @media (max-width: 776px) {

        .text-prop-key {
            max-width: 40%;
        }

        .text-prop-value {
            max-width: 50%;
        }

        .image-gallery-image {
            min-height: 60vh !important;
            max-height: 60vh !important;
        }
    }

    @media (max-width: 486px) {

        .text-prop-key {
            max-width: 45%;
        }

        .text-prop-value {
            max-width: 50%;
        }
    }
}