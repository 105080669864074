.custom-slider .slick-dots {
  position: relative;
  bottom: 0;
  left: 48%;
  transform: translateX(-50%);
  padding: 0 480px;
  display: flex !important;
  justify-content: center !important;
}

.custom-slider .slick-dots li {
  display: block;
  margin: 0 -3px;
}

.hover-div:hover {
  background-color: rgb(106 63 240 / 11%) !important;
  /* Change this to the color you want on hover */
}

.image-item-container {
  width: 60px;
  /* Set the desired width */
  overflow: hidden;
  /* Clip any overflow */
}

.image-item-container img {
  width: 100%;
  /* Ensure the image takes up the full width of the container */
  height: auto;
  /* Automatically adjust the height to maintain the original aspect ratio */
}
