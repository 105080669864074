.contact-us {
    background: linear-gradient(125.69deg, #F8F9FA 2.21%, #F2E5FC 70.46%);
    margin-top: -48px;
    margin-bottom: -48px;

    .breadcrumb {
        padding-top: 24px;
        padding-bottom: 16px;
    }

    .card {
        border-radius: 15px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-bottom: 50px;
        max-width: 450px;
        width: 450px;
    }

    .card-header {
        background-color: white;
        border-bottom: none;
        border-radius: 15px 15px 0 0;
        padding-top: 20px;
        padding-left: 2rem;
    }

    .card-header h5 {
        font-weight: 600;
    }

    .card-body {
        padding: 2rem;
        padding-top: 0rem;
    }

    .form-control {
        border-radius: 5px;
        margin-bottom: 1rem;
    }

    .btn-primary {
        background-color: #007bff;
        border: none;
        border-radius: 5px;
        padding: 0.5rem 2rem;
    }

    .btn-primary:hover {
        background-color: #0056b3;
    }

    .contact-card {
        // background-color: #f8f9fa;
        // border-radius: 15px;
        padding: 2rem;
        // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        // text-align: center;
    }


    @media (max-width: 768px) {
        .contact-card {
            padding: 0rem 1rem 2rem 1rem;
        }

        .card {
            margin-left: 10px;
            margin-right: 10px;
        }

    }

    .contact-card h4 {
        font-weight: bold;
        margin-bottom: 1.5rem;
    }

    .contact-card p {
        font-size: 16px;
        margin-bottom: 2rem;
    }

    .contact-info .info-item {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        gap: 10px;
    }

    .contact-info .icon {
        font-size: 24px;
        color: #6f42c1;
        margin-right: 1rem;
    }

    .contact-info h6 {
        margin: 0;
        font-weight: bold;
    }

    .contact-info p {
        margin: 0;
    }

    .social-media {
        margin-top: 2rem;
    }

    .social-media h6 {
        margin-bottom: 1rem;
        font-weight: bold;
    }

    .social-icons .social-icon {
        font-size: 24px;
        color: #6f42c1;
        margin: 0 10px;
        cursor: pointer;
    }

    .social-icons .social-icon:hover {
        color: #0056b3;
    }

    textarea::placeholder {
        color: lightgray;
    }
}