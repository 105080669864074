.privacy-policy {
    * {
        color: black;
    }

    h1 {
        margin-top: 10px;
        margin-bottom: 30px;
        font-weight: 700;
    }

    h2 {
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: 600;
    }

    h3 {
        margin-top: 0px;
        margin-bottom: 20px;
        font-weight: 600;
    }

    h4 {
        margin-top: 0px;
        margin-bottom: 20px;
        font-weight: 500;

    }

    p {
        line-height: 1.5;
    }

    a,
    span,
    strong {
        display: inline;
    }

    a {
        text-decoration: underline;
    }

    ul {
        margin-left: 1rem;
        padding-left: 20px;
    }

    li {
        text-indent: -20px;
        padding-left: 20px;
        margin-bottom: .25rem;
        display: list-item;
        list-style-type: disc;
        list-style-position: inside;
        margin-bottom: .25rem;
        width: 100%;

        p {
            display: inline;
        }
    }
    ul li:last-child{
        margin-bottom: 20px;
    }

    .contact-info {
        font-weight: bold;
    }
}