input::placeholder {
  color: grey;
}

tr,
td {
  border-style: none;
}

th {
  border: 1px solid lightgray;
  font-weight: 500;
  text-align: left !important;
}

tbody {
  border: 1px solid lightgray;
  border-left: none;
}

tbody tr:last-child th {
  padding-bottom: 30px;
  vertical-align: top;
}

.compare__title {
  font-size: 15px;
}

input::placeholder {
  font-size: 15px;
}

.add__card {
  height: 377px;
}

.phone__img {
  margin-top: 80px;
  width: 340px;
  height: 150px;
}

.mob-img {
  max-height: 300px;
  padding-top: 20px;
}

@media (min-width: 1324px) {
  .top-col {
    display: none;
  }

  .sec-top-col {
    display: none;
  }

}

@media (max-width: 1324px) {
  .side-col {
    display: none;
  }

  th {
    display: none;
  }

  tbody {
    border-left: 1px solid lightgray;
  }

  .top-col h6 {
    border-radius: 10px;
    border: 1px solid lightgray;
  }

  .sec-top-col {
    padding: 0px !important;
  }
}

@media (max-width: 1200px) {
  .tablet-view-none {
    display: none;
  }
}

@media (min-width: 1200px) {
  .tablet-view-none {
    display: block;
  }
}

@media (max-width: 564px) {
  .heading__mobile {
    display: none;
  }
  
}

@media (max-width: 467px) {
  .add__card {
    height: 277px;
  }

  .phone__img {
    margin-top: 20px;
    width: 150px;
    height: 150px;
  }
  
  .mob-img {
    max-height: 200px;
    padding-top: 20px;
  }
}

.search-result-row:hover {
  background-color: #e9ecef;
}


.ComparisonTable table {
  width: 100%;
}

.ComparisonTable td {
  width: 24%;
  text-align: center;
}

tr.features__mobile td {
  max-width: 15%;
}

th {
  max-width: 13%;
}

.side-col {
  vertical-align: top;
  // background-color: white;
  border: none;
  border-top-style: hidden;
  border-bottom-style: hidden;
}

@media (max-width: 564px) {
  .side-col {
    display: none;
  }
}

.dull{
  color: rgba($color: #000000, $alpha: 0.3);
  font-size: 14px;
}