.new-mobiles-parent-class {
  .range-filter {
    width: 80%;
    margin: 0 auto;
    position: relative;
  }

  .range-filter input[type="range"] {
    width: 100%;
    margin-top: 1rem;
    background-color: transparent;
  }

  .range-filter input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #7B2CBF;
    border-radius: 50%;
    cursor: pointer;
  }

  .range-filter input[type="range"]::-webkit-slider-thumb:active {
    background-color: #7B2CBF;
  }

  .range-filter input[type="range"]::-webkit-slider-runnable-track {
    height: 5px;
    background-color: #7B2CBF;
    border-radius: 5px;
  }

  .range-filter input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background-color: #007bff;
    border-radius: 50%;
    cursor: pointer;
  }

  .range-filter input[type="range"]::-moz-range-thumb::before {
    content: "\2022";
    /* Bullet character */
    display: block;
    font-size: 1rem;
    color: white;
    text-align: center;
    line-height: 20px;
  }

  .range-filter input[type="range"]::-moz-range-thumb:active {
    background-color: purple;
  }

  .range-filter input[type="range"]::-moz-range-track {
    height: 5px;
    background-color: #ccc;
    border-radius: 5px;
  }

  .loading {

    display: flex;
    justify-content: center;
    mix-blend-mode: multiply;
  }

  @media (width: 375px) {
    .gridViewCard {
      width: 170px;
      height: 231px;
    }

    .mobileBrand {
      font-size: 13px,
    }

    .mobilePrice {
      font-size: 15px,
    }

    .ratingSize {
      font-size: 13px,
    }

    .mobileReviews {
      font-size: 13px,
    }
  }

  @media (max-width: 767px) {
    .grid__card {
      width: 104% !important;
    }

    .price {
      font-size: 18px !important;
    }

    .price_rating {
      bottom: 19px !important;
    }

    .rating {
      font-size: 13px !important;
    }

    .reviews {
      font-size: 12px !important;
    }
  }

  @media (max-width: 768px) {
    .list__card {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .card-title,
    .card-text {
      text-align: center;
    }

    .rating {
      align-items: center;
    }

    // .price {
    //  font-size: 18px !important;
    // }
    // .price_rating {
    //   bottom: 19px !important;
    // }
    // .rating{
    //   font-size: 13px !important;
    // }
    // .reviews{
    //   font-size: 12px !important;
    // }
  }

  .mobile-card {
    width: 100%;
    background-color: #F8F9FA;
    margin: auto;
    border-radius: 20px;
    border: 0
  }


  @media (min-width: 1199px) {
    .mobile-card {
      min-height: 254px;
    }

    .rating {
      flex-direction: row;
      justify-items: center;
    }
  }

  @media (max-width: 1199px) {
    .mobile-card {
      min-height: 300px;
    }

    .rating {
      flex-direction: column;
    }
  }

  @media (max-width: 440px) {
    .mobile-card {
      min-height: 330px;
      width: 90% !important;
    }
  }

  .mobile-card:hover {
    background-color: rgb(106 63 240 / 11%) !important;
    /* Change this to the color you want on hover */
  }
}