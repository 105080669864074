///////////////////////////////
////// AboutUsBanner.tsx //////
///////////////////////////////


.breadcrumb-item {
    font-size: 12px;
    font-weight: 300
}

.container-fluid {
    padding: 0;
}

.about_us_img {
    width: 100%;
    object-fit: contain;
    margin-top: 20px;
    margin-bottom: 20px;
}


@media (max-width: 767px) {
    .about_us_img {
        margin-top: -10rem;
        margin-bottom: 0px;
    }
}

@media (max-width: 467px) {
    .about_us_img {
        margin-top: -8rem;
    }
}

@media (max-width: 350px) {
    .about_us_img {
        margin-top: -6rem;
    }
}

@media (max-width: 300px) {
    .about_us_img {
        margin-top: -4rem;
    }
}

.heading-bold {
    font-weight: bolder;
}

.banner-btn {
    font-weight: lighter;
    padding: 8px 16px;
    border-radius: 12px;
    color: white;
    background-color: #7B2CBF;
    border: 1px solid #7B2CBF;
}
.banner-btn:hover{
    border: 1px solid #7B2CBF;
    color: #7B2CBF;
}


/////////////////////////////
////// MeetOurTeam.tsx //////
/////////////////////////////


.meetOurTeam {
    padding-top: 30px;
    padding-bottom: 150px;
    background-color: #F8F7FC;
}

.custom-card {
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border: none;
    background: #FFFFFF;
    min-height: 320px;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between; // This helps in evenly spacing the content
}

.card-subtitle {
    color: #5A189A;
}

.card-text {
    font-weight: lighter;
    font-size: 14px;
}

.avatar img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.social-links {
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    opacity: 0.6;
}