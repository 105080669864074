$red: #fc4444;
$black: #313131e3;
$transparent: transparent;



  .custom-button {
    
  background-color: $transparent;
  color: $black;
  border: none;
//   padding: 10px 20px;
  font-size: 16px;
  padding: 0;
//   display: inline-block;
//   align-items: center;
//   justify-content: center;
cursor: pointer;
.btnText{

  transform: translateX(0);
}
&:hover {
    color: $red;
    .btnText{
      transform: translateX(50);
      
    }
    .icon {
      display: inline-block;
      padding:0px 1px;
      //   margin-left: 5px;
      // transition: margin-left 0.5s ease;
      transform: translateX(0);
    }
  }
  
  .icon  {
    // transition: transform 0.6s ease;
    display: none;
    transform: translateX(-100%);
    // margin-right: 0;
  }
}

// ----social buttons---------
.facebook {
    color: white;
    background-color: #3b5998;
    &:hover{
        color: #3b5998;
        border: 1px solid #3b5998;
        // background:white;
    }
  }
  
  .pinterest {
    color: white;
    background-color: #bd081c;
    &:hover{
        color: #bd081c;
        border: 1px solid #bd081c;
      }
  }
  .google {
    color: white;
    background-color: #db4437;
    &:hover{
        color: #db4437;
        border: 1px solid #db4437;
    }
  }
  .twitter {
    color: white;
    background-color: #1da1f2;
    &:hover{
        color: #1da1f2;
        border: 1px solid #1da1f2;
    }
  }

  // -----signup-------
  .zoom-button {
    position: relative;
    display: inline-block;
    // padding: 7px 15px;
    background-color: rgb(250, 36, 36);
    color: white;
    border: none;
    border-radius: 30px;
    // font-size: 14px;
    cursor: pointer;
    overflow: hidden;
  }
  
  .zoom-button::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 100%;
    height: 100%;
    background-color: rgba(161, 161, 161, 0.459);
    transition: transform 0.3s ease;
    pointer-events: none;
  }
  
  .zoom-button:hover::before {
    animation: zoom-out 1.5s infinite;
    // animation-play-state: paused;
  }
  
  @keyframes zoom-out {
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
    }
    50% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(2);
      opacity: 0;
    }
  }
  
  .zoom-button span {
    position: relative;
    z-index: 1;
  }
  // -----privacy
  .btnPrivacy{
    color:#313131e3;
    &:hover{
      color: #fc4444;
    }
  }