$primary: #6A3FF0;
$info: #9D4EDD;
$secondary:#F8F9FA;


@import './scss/bootstrap';
@import './scss/button';
@import "~react-toastify/dist/ReactToastify.css";
@import "~react-image-gallery/styles/scss/image-gallery.scss";



*{
  font-family: Roboto;
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
}
.hover-div:hover {
  background-color: rgb(106 63 240 / 11%) /* Change this to the color you want on hover */
}
.image-item-container {
  width: 60px; /* Set the desired width */
  overflow: hidden; /* Clip any overflow */
}
.image-container img {
  width: 100%; /* Ensure the image takes up the full width of the container */
  height: auto; /* Automatically adjust the height to maintain the original aspect ratio */
}