.blogs-parent-div {

    .blog-card {
        background: white;
        color: white;
        border: none;
        border-radius: 15px;
        margin: 0;
        min-height: 48.5%;
    }
    .blog-card-non-first{
        max-height: 48.5%;
    }

    .blog-card-first{
        height: 100%;
    }
    .card-body {
        position: absolute;
        bottom: 40px;
    }

    .blog-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
        vertical-align: auto;
    }

    // latest blog cards overlay
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 15px;
    }

    .outer-desc-div {
        display: flex;
        align-items: center;
        gap: 100px;
        margin-top: 10px;
    }

    .inner-desc-div {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .outer-desc-div .card-text {
        color: white;
        font-weight: 400;
    }

    .top-card-div {
        max-height: 600px;
        min-height: 600px;
    }

    /////

    .blog-card-recommended {
        // max-width: 18rem;
        border: none;
        border-radius: 10px;
        // margin: auto;
        margin: 0;
        position: relative;
        background-color: #FFFFFF;
        padding: 0px 14px;
        //     display: flex;
        //     flex-direction: column;
        // justify-content: space-between;
    }

    // .blog-card-image-container {
    //     position: relative;
    //     // overflow: hidden;
    // }

    // .blog-card-image {
    //     height: 160px;
    //     object-fit: cover;
    //     object-position: center;
    // }

    .blog-card-image-container {
        position: relative;
        width: 100%;
        height: 160px;
        overflow: hidden;
        border-radius: 15px;
        color: black;
    }

    .latest-news-btn {
        background-color: #C77DFF;
        width: fit-content;
        border-radius: 5px;
        border: 0px;
        color: white;
        font-weight: 300;
        padding: 4px 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .blog-card-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .blog-card-body {
        padding: 15px;
    }

    .blog-card-title {
        // padding-top: 12px;
        padding-bottom: 8px;
        font-size: 1.2rem;
        color: #000;
        min-height: 66px;
        z-index: 1000;
    }

    .blogs-top-section .card-title {
        min-height: 80px;
        z-index: 1000;
    }

    .blog-card-text {
        font-size: 0.875rem;
        color: #000;
        font-weight: 400;
        max-height: 100px;
        overflow: hidden;
        margin-bottom: 40px;
    }

    .blog-card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        bottom: 0;
        position: absolute;
        width: 90%;
    }

    @media (max-width: 768px) {
        .blog-image {
            height: auto;
            min-height: 100%;
        }

        .blog-image img {
            min-height: 100%;
        }

        // .top-card-div2 .blog-card {
        //     margin-bottom: 18px;
        // }

        .blog-card{
            min-height: 400px;
            // max-height: 400px;
        }
        // .blog-card,
        // .blog-image {
        //     min-height: 400px;
        // }

        .top-card-div {
            max-height: 900px;
        }

        .blog-card-title {
            min-height: auto;
        }

        .card-title {
            text-align: left;
        }
    }

    @media (max-width: 576px) {
        .blog-card-recommended {
            max-width: 100%;
        }
    }
}