.screen2 {
    .stepper-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
    }

    .stepper {
        list-style: none;
        display: flex;
        justify-content: space-between;
        width: 100%;
        // max-width: 1000px;
        padding: 0;
        overflow: hidden;
    }

    .step {
        display: flex;
        flex-direction: column;
        // align-items: center;
        // text-align: center;
        align-items: flex-end;
        text-align: right;
        position: relative;
        flex: 1;
        padding-right: 30px;
        /* Add padding to the right */
    }

    .step::after {
        content: '';
        position: absolute;
        top: 26%;
        // right: 40%;
        width: 100%;
        height: 3px;
        background-color: #e0e0e0;
        transform: translateY(-20%);
        z-index: -1;
    }

    .circle {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid #e0e0e0;
        background-color: white;
        color: #e0e0e0;
    }

    .label {
        margin-top: 10px;
        color: #333;
        font-size: 14px;
        margin-right: -20px;
        // margin-right: -16%;

        white-space: nowrap;
        /* Prevent text from wrapping */
        overflow: visible;
        /* Ensure text overflows the container */
    }

    .step.active .circle {
        border-color: #d27cf7;
        // background-color: white;
        // color: #d27cf7;
        color: white !important;
        background-color: #d27cf7 !important;
    }

    .step.active.current-active .circle {
        background-color: white !important;
        color: #d27cf7 !important;
    }

    .step.active:last-child .circle {
        background-color: #d27cf7;
    }

    .step.active .label {
        color: #d27cf7;
    }

    .step.active::after {
        background-color: #d27cf7;
    }


    @media (max-width: 768px) {
        .stepper-container {
            display: none;
        }
    }

    /////////////
    /// State 1
    /////////////

    .parent-top-banner-state1 {
        background-color: #F8F9FA;
        border-radius: 20px;
        padding: 40px 30px;
        margin-top: 20px !important;

        label {
            margin-top: 10px;
            font-weight: 500;
            padding: 0;
        }

        .head-title {
            padding: 0;
        }

        input,
        textarea {
            width: 100%;
            margin: 8px 0;
            padding: 10px;
            border-radius: 7px;
            border: none;
            box-sizing: border-box;
        }

        textarea:focus {
            outline: none;
        }

        textarea {
            resize: none;
        }


        textarea::placeholder {
            opacity: 0.4;
        }

        p {
            margin: 0;
            padding: 0;
        }

        .desc-label.error-field {
            color: #F20909;
        }

        .desc-textarea.error-field {
            border: 1px solid #F20909;
        }

        .desc-p.error-field {
            color: #F20909;
            display: block;
        }

        .desc-p {
            display: none;
        }

        .title-label.error-field {
            color: #F20909;
        }

        .title-input.error-field {
            border: 1px solid #F20909;
        }

        .title-p.error-field {
            color: #F20909;
            display: block;
        }

        .title-p {
            display: none;
        }
    }

    .parent-top-banner-state2 {

        background-color: #F8F9FA;
        border-radius: 20px;
        padding: 40px 30px;
        margin-top: 20px !important;

        label {
            margin-top: 10px;
            font-weight: 500;
            padding: 0;
        }

        .head-title {
            padding: 0;
        }


        input {
            width: 100%;
            margin: 8px 0;
            padding: 10px;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
            border: none;
            box-sizing: border-box;
        }

        textarea {
            width: 100%;
            margin: 8px 0;
            padding: 10px;
            border-radius: 7px;
            border: none;
            box-sizing: border-box;
            resize: none;
        }

        select {
            width: 100%;
            margin: 8px 0;
            padding: 10px;
            border-radius: 7px;
            border: none;
            box-sizing: border-box;
        }


        textarea::placeholder {
            opacity: 0.4;
        }

        .input-container {
            display: flex;
            align-items: center;
            width: 100%;
            box-sizing: border-box;
        }

        .fixed-text {
            background-color: white;
            padding: 10px;
            padding-right: 5px;
            white-space: nowrap;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
        }

        // .input-container input {
        //     border: none;
        //     outline: none;
        //     flex: 1;
        //     padding: 0;
        //     margin: 0;
        // }
        .dropdown {
            position: absolute;
            background-color: white;
            border: 1px solid #ccc;
            z-index: 1000;
            width: 100%;
            /* This ensures it matches the input field's width */
        }

        .dropdown-item {
            padding: 8px;
            cursor: pointer;
        }

        .dropdown-item:hover {
            background-color: #f0f0f0;
        }

    }

    .parent-top-banner-state3 {
        background-color: #F8F9FA;
        border-radius: 20px;
        padding: 40px 30px;
        margin-top: 20px !important;


        label {
            margin-top: 10px;
            font-weight: 500;
            padding: 0;
        }

        .head-title {
            padding: 0;
        }

        input,
        textarea {
            width: 100%;
            margin: 8px 0;
            padding: 10px;
            border-radius: 7px;
            border: none;
            box-sizing: border-box;
        }

        textarea {
            resize: none;
        }

        textarea::placeholder {
            opacity: 0.4;
        }

        .head-row {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0;
        }

        p {
            padding: 0;
        }

        .image-upload-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;
            // max-width: 1000px;
            margin-top: 20px;
        }

        .image-box {
            width: 80px;
            height: 80px;
            background-color: white;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        }

        .image-box label {
            margin: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .placeholder-image img {
            width: 30px;
        }

        .uploaded-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 10px;
        }

        .image-wrapper {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
        }

        .remove-icon {
            position: absolute;
            top: -10px;
            right: -10px;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 18px;
            color: #ff0000;
            font-weight: bold;
        }

        .remove-icon:hover {
            background: rgba(255, 255, 255, 1);
        }

    }


    .parent-top-banner-state4 {

        background-color: #F8F9FA;
        border-radius: 20px;
        padding: 40px 30px;
        margin-top: 20px !important;

        label {
            margin-top: 10px;
            font-weight: 500;
            padding: 0;
        }

        .head-title {
            padding: 0;
        }


        input {
            width: 100%;
            margin: 8px 0;
            padding: 10px;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
            border: none;
            box-sizing: border-box;
        }

        textarea {
            width: 100%;
            margin: 8px 0;
            padding: 10px;
            border-radius: 7px;
            border: none;
            box-sizing: border-box;
            resize: none;
        }

        select {
            width: 100%;
            margin: 8px 0;
            padding: 10px;
            border-radius: 7px;
            border: none;
            box-sizing: border-box;
        }


        textarea::placeholder {
            opacity: 0.4;
        }

        .input-container {
            display: flex;
            align-items: center;
            width: 100%;
            box-sizing: border-box;
        }

        .fixed-text {
            background-color: white;
            padding: 10px;
            padding-right: 5px;
            white-space: nowrap;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
        }

    }


    .parent-top-banner-state5 {
        background-color: #F8F9FA;
        border-radius: 20px;
        padding: 40px 30px;
        margin-top: 20px !important;

        label {
            margin-top: 10px;
            font-weight: 500;
            padding: 0;
        }

        .head-title {
            padding: 0;
        }

        input,
        textarea {
            width: 100%;
            margin: 8px 0;
            padding: 10px;
            border-radius: 7px;
            border: none;
            box-sizing: border-box;
        }

        textarea {
            resize: none;
        }


        textarea::placeholder {
            opacity: 0.4;
        }


        .input-container {
            display: flex;
            align-items: center;
            width: 100%;
            box-sizing: border-box;
            padding: 0;
        }

        .fixed-text {
            background-color: white;
            padding: 10px;
            padding-right: 5px;
            white-space: nowrap;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
        }
    }


    .button-parent {
        display: flex;
        align-items: center;
    }

    .popup-overlay-success {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-Index: 1000;

        .popup-content {
            background-color: white;
            padding: 40px 20px;
            border-radius: 10px;
            text-align: center;
            max-width: 500px;
            width: 90%;
            position: relative;
        }

        .popup-content h2 {
            font-size: 22px;
            font-weight: 600;

        }

        .popup-content img {
            margin: 20px 0;
        }

        .popup-content p.first-text {
            font-weight: bold;
            margin-bottom: 4px;
        }
        .popup-content p {
            font-size: 16px;
            color: #333;
        }

        .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            background: none;
            border: none;
            font-size: 24px;
            cursor: pointer;
        }

    }
}