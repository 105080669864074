// .screenLoader {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100vh;
//     mix-blend-mode: multiply;
//     background-color: white;
// }

.screenLoader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 1000;
}