.navbar {
  background-image: linear-gradient(to left, #4f2179, #441f65, #391d51, #2e1a3e, #23172c, #1e1624, #19141d, #141215, #141215, #141215, #141215, #141215);
}

/* Add this CSS to your stylesheet */


@media (min-width: 992px) {
  .first__flag {
    display: none;
  }

  .second__flag {
    display: visible;
  }
}

@media (max-width: 992px) {
  .first__flag {
    display: block;
    margin: 0 auto;
    margin-right: 0px;
  }

  .second__flag {
    display: none;
  }

  .country-dropdown {
    transform: translateX(6%);
  }

}


ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  color: white;
}

ul:hover {
  overflow: visible;
}

ul li {
  position: relative;
  float: left;
  color: white;
}

a {
  display: block;
  color: white;
  text-decoration: none;
}

/* second level */
ul ul {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  background-color: transparent;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  width: 120px;
  padding: 0;
}

ul li:hover ul {
  max-height: 999px;
}

ul ul li {
  float: none;
  width: 100%;
}

ul ul li:hover ul {
  max-height: 999px;
}

// ul ul a{
//   padding: 0px 0px;
// }
ul ul:after {
  content: '';
  display: table;
  clear: both;
}




// smaller screens 
/* ... previous styles ... */

/* Apply these styles for smaller screens */
@media (max-width: 992px) {
  .rev-dir {
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .second-flag-display{
    display: none;
  }
}

@media (max-width: 768px) {
  ul {
    position: relative;
    /* Adjust position to make dropdowns appear below on smaller screens */
  }

  ul ul {
    position: static;
    /* Remove absolute positioning for small screens */
    top: auto;
    /* Reset top position */
    left: auto;
    /* Reset left position */
    z-index: 1;
    /* Bring dropdowns below the main nav items */
    background-color: transparent;
    /* Set background color for dropdowns */
    max-height: 0;
    /* Hide dropdowns by default */
    overflow: hidden;
    /* Hide content */
    transition: max-height 0.5s ease-in-out;
    /* Add transition effect for smooth appearance */
    width: 100%;
    /* Set width to 100% to cover the full screen width */
  }

  ul li:hover>ul {
    max-height: 999px;
    /* Show dropdowns on hover */
  }

  ul ul li {
    width: 100%;
    /* Ensure dropdown items take full width */
  }

  ul ul li:hover ul {
    max-height: 999px;
    /* Keep nested dropdowns open on hover */
  }

  ul ul:after {
    content: '';
    display: table;
    clear: both;
  }

}

ul li:hover ul {
  max-height: 999px;
}

.navbar {
  .container {
    .collapse {

      .navbar-nav .nav-item {
        transition: left 0.4s cubic-bezier(.88, -.35, .565, 1.35);
      }

      .nav-item.active {
        background-color: #4f2179;
        border-radius: 20px;
      }

      .navbar-nav .nav-item {
        border: 2px solid transparent;
        border-radius: 20px;
      }

      .navbar-nav .nav-item:hover {
        border: 2px solid #4f2179;
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }
}

.tablets-dropdown {
  background-image: linear-gradient(to left, #4f2179, #441f65, #391d51, #2e1a3e, #23172c, #1e1624, #19141d, #141215, #141215, #141215, #141215, #141215);
  /* Set the background to transparent when active */
  border-radius: 15px;
  margin: 1px -2px;
}

.tablets-dropdown.active {
  background-color: #4f2179;
  /* Set the background to transparent when active */
}

.tablets-dropdown li:hover {
  background-color: #4f2179;
  /* Set the background color on hover */
}

/* Styles for the country dropdown */
.country-dropdown {
  position: absolute;
  top: 100%;
  right: 0px;
  margin-top: 6px;
  width: 120px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;
  max-height: 300px;
  overflow-y: auto;
}

.country-item {
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.2s ease;
}

.country-item:last-child {
  border-bottom: none;
}

.country-item:hover {
  background-color: #f7f7f7;
}