.blogpost .outer-desc-div img {
    border: 1px solid black;
    border-radius: 50%;
}

.blogpost .outer-desc-div p {
    margin: 0;
    color: gray;
}

.outer-desc-div {
    display: flex;
    align-items: center;
    gap: 100px;
    margin-top: 10px;
}

// margin-top: 0px;

.inner-desc-div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.blogpost_content {
    li {
        color: black;
        width: 100%;
    }

    // h1,
    // h1:hover {
    //     margin-top: 0;
    // }

    // h2,
    // h2:hover {
    //     margin-top: 0;
    // }

    // h3,
    // h3:hover {
    //     margin-top: 0;
    // }

    // h4,
    // h4:hover {
    //     margin-top: 0;
    // }

    // h5,
    // h5:hover {
    //     margin-top: 0;
    // }

    // h6,
    // h6:hover {
    //     margin-top: 0;
    // }

    ul {
        // ul:hover {

        list-style: disc;
        margin-top: 0;
        padding-left: 30px;
        padding-bottom: 20px;
        display: inline-block;
    }

    // li,
    // li:hover {
    //     margin-top: 0;
    // }
}