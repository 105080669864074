/* VideoGrid.css */
.video-card {
    position: relative;
    margin-bottom: 1rem;
}

.video-thumbnail {
    position: relative;
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../../../assets/play.png");
    z-index: 100;
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
    background-size: contain;
}

.btn.play-button:focus {
    border: none;
}


.video-info {
    margin-top: 0.5rem;
    padding: 0px 4px
}

.img-fluid {
    width: 100%;
    border-radius: 20px;
    z-index: 10;
}

.title {
    font-family: Roboto;
    font-weight: 700;
    font-size: 16px;
}

.video-info .date,
.video-info .category {
    font-family: Roboto;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 0px;
}

.ant-pagination {
    padding: 10px;
    line-height: 32px !important;
}

.ant-pagination-item-active {
    border: none !important;
    line-height: 32px !important;
}

.ant-pagination-item-active button {
    background-color: rgb(157, 78, 221) !important;
    color: rgb(248, 249, 250) !important;
}

.video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; // Ensure it's above other items
}

.video-container {
    position: relative;
    width: 90%;
    max-width: 640px; // Adjust based on your needs

    iframe {
        width: 100%;
        height: 380px; // Adjust based on your needs
        border-radius: 20px;
    }
}

.video-close-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    padding: 5px 6px;
    cursor: pointer;
}

.video-close-btn:hover {
    background-color: #ECECEC;
}