.banner {
  background-image: linear-gradient(to left, #4f2179, #441f65, #391d51, #2e1a3e, #23172c, #1e1624, #19141d, #141215, #141215, #141215, #141215, #141215);
  height: 90vh;
  overflow: hidden;
}

.banner_mainText {
  font-size: 34px;
  font-weight: 700;
  font-family: 'Roboto';
}

.banner_middleText {
  font-size: 32px;
  font-weight: 400;
  font-family: 'Roboto';
}

.banner_endText {
  font-size: 40px;
  font-weight: 600;
  font-family: 'Roboto';
  word-spacing: 1px;
}

input {
  width: 115px;
}

input::placeholder {
  color: lightgray !important;
}

@media (max-width: 838px) {
  .img-header {
    display: none;
  }

}


@media (max-width: 764px) {
  .img-header {
    display: none;
  }
}
@media (max-width: 400px) {
  .suggestion-dropdown {
    max-width: 280px !important;
  }
}
@media (max-width: 360px) {
  .suggestion-dropdown {
    max-width: 260px !important;
  }
}
@media (max-width: 300px) {
  .suggestion-dropdown {
    max-width: 200px !important;
  }
}

//  Search bar city and range
@media (max-height: 700px) {
  .banner {
    height: 100vh !important;
  }
}
@media (max-width: 467px) {
  .banner {
    height: 80vh;
  }

  .banner_mainText {
    font-size: 28px;
  }

  .banner_middleText {
    font-size: 23px;
  }

  .banner_endText {
    font-size: 33px;
  }


  /* Hide the city field */
  select[name="city"] {
    display: none;
  }

  /* Hide the range field */
  select[name="range"] {
    display: none;
  }
}

input:focus,
select:focus {
  outline: none;
}


@media (max-width: 467px) {
  .banner_text {
    font-size: 20px;
    /* Adjust the font size as per your preference */
  }

  /* Styles for screens smaller than 768px (e.g., mobile screens) */
  .search__Group {
    width: 100%;
    /* Set the width to 100% */
    /* Add any other specific styles you want to apply on small screens */
  }

  // .phone__box {
  //   /* Keep the width at 230px for small screens */
  //   width: 230px;
  // }
}


// 7B2CBF


.active-brand {
  font-weight: bold;
  /* or any other style to indicate it's selected */
}

.filters_section_body_heading {
  display: none;
}

.filters_btn {
  display: none;
}

/* Example media query for hiding the sidebar on smaller screens */
@media (max-width: 768px) {

  .filters_section {
    background-color: #ffffff;
    width: 0 !important;
    overflow-x: hidden !important;
    transition: all 0.3s;
    position: fixed;
    top: 0;
    right: 0;
    padding: 0;
  }

  .filters_section_heading {
    display: none;
  }

  .filters_btn {
    padding: 2.5px 3px 2.5px 2px;
    border-radius: 3.5px !important;
    background-color: #f8f9fa !important;
  }

  .show_filters_sidebar {

    width: 450px !important;
    height: 100vh;
    z-index: 1024 !important;
    margin: 0 0 0 46.5px;
    padding: 0 10px 0 10px;
    overflow-y: scroll;

  }


  .filters_section_body_heading {
    display: flex;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 15px 0 7px;
  }

  .filters_section_body_heading h5 {
    display: flex;
    align-items: center;
  }

  .filters_btn {
    display: flex;
  }

}


/* Example media query for showing the button on smaller screens */
@media (max-width: 768px) {
  .toggle-sidebar-button {
    display: block;
  }
}

@media (max-width: 808px) {
  .search__keywords {
    display: block !important;
  }
}

/* Example media query for hiding the button on larger screens */
@media (min-width: 769px) {
  .toggle-sidebar-button {
    display: none;
  }
}


// Add any additional styles you need