.user-ads-parent {

    .ad-filters {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        /* Allows buttons to wrap on smaller screens */
    }

    .ad-item {
        background-color: #f8f9fa;
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        /* Ensures wrapping on smaller screens */
    }

    .ad-image img {
        width: 80px;
        height: 80px;
        object-fit: cover;
    }

    .ad-details {
        flex-grow: 1;
    }

    .ad-title {
        font-size: 16px;
        font-weight: bold;
    }

    .ad-category {
        font-size: 12px;
        color: #888;
    }

    .ad-price {
        font-size: 18px;
        font-weight: bold;
    }

    .ad-status {
        font-size: 12px;
        color: #888;
    }

    .ad-status .badge {
        font-size: 12px;
        margin-left: 5px;
    }

    .ad-stats {
        display: flex;
        gap: 10px;
        font-size: 14px;
    }

    .ad-actions {
        display: flex;
        flex-direction: row;
        gap: 2px;
        margin: 16px 0px;
    }

    @media (max-width: 768px) {
        .ad-item {
            flex-direction: column;
            /* Stack items vertically on small screens */
            text-align: center;
        }

        .ad-image img {
            width: 60px;
            height: 60px;
        }

        .ad-title {
            font-size: 14px;
        }

        .ad-price {
            font-size: 16px;
        }

        .ad-actions {
            justify-content: center;
            /* Center the buttons on smaller screens */
            margin-top: 10px;
        }

        .ad-stats {
            justify-content: center;
            gap: 20px;
            /* Reduce the gap between stats */
        }
    }

    @media (max-width: 576px) {
        .ad-stats {
            flex-direction: column;
            gap: 5px;
            /* Stack stats vertically on smaller screens */
        }

        .ad-actions {
            flex-direction: column;
            gap: 10px;
        }
    }

}